import React, { useState, useEffect, useRef } from 'react';
import './all_ads.css';
import { Row, Col, Form, Button, Card, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import { FaSearch, FaRegStar } from 'react-icons/fa'
import LocalIP from "../../LocalIP";
import axios from "axios"
import { Drawer } from "antd"
import { FaFilter } from "react-icons/fa"
import { useHistory, useLocation } from 'react-router-dom';
import { BiSolidDetail } from "react-icons/bi";

const All_ads = () => {

  const location = useLocation();
  const all_adsRef = useRef(null);
  const [type, setType] = useState('');
  const [category, setCategory] = useState('');
  const [sub_machinery_temp, setSub_machinery_temp] = useState([]);
  const [adsTypeCategoryData, setAdsTypeCategoryData] = useState(false);
  const [subCategory, setSubCategory] = useState('');
  const [sub_machinery, setSub_machinery] = useState([]);
  const [machinery, setMachinery] = useState([]);
  const [model, setModel] = useState('');
  const [manufacture, setManufacture] = useState('');
  const [allads, setAllads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(12);
  const [totalAds, setTotalAds] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [adsTypeRentalData, setAdsTypeRentalData] = useState(true);
  const [visible, setVisible] = useState(false);
  const [area, setArea] = useState([]);
  const [search, setSearch] = useState('');
  const [areaSearch, setAreaSearch] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [services, setServices] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  const history = useHistory();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => onReload(), []);

  const setTypeForm = (e) => {
    setType(e.target.value)
    setCategory("")
    if (e.target.value == "Rental" || e.target.value == "Trading") {
      setAdsTypeRentalData(true)
      setAllCategory(machinery)
    } else {
      setAdsTypeRentalData(false)
      setAdsTypeCategoryData(false)
      setAllCategory(services)
    }
  }

  const timeAgo = (dateTime) => {
    const now = new Date();
    const past = new Date(dateTime.replace(' ', 'T'));
    const timeDifference = now - past;

    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? 's' : ''}`;
    } else {
      return `${weeks} week${weeks !== 1 ? 's' : ''}`;
    }
  }

  const ads_title_limit = (title) => {
    if (title.length > 20) {
      return title.substring(0, 20) + "...";
    }
    return title;
  }

  const onReload = () => {
    const queryParams = new URLSearchParams(location.search);
    const url = LocalIP + "machinery/";
    axios.get(url).then((response) => {
      console.log(response["data"])
      setMachinery(response["data"])
      if (queryParams.get('type') !== null) {
        setType(queryParams.get('type'))
        typeTemp = queryParams.get('type')
        if (queryParams.get('type') == "Rental" || queryParams.get('type') == "Trading") {
          setAdsTypeRentalData(true)
          setAllCategory(response["data"])
        }
      }
    });
    const url2 = LocalIP + "machinery/services/";
    axios.get(url2).then((response) => {
      console.log(response["data"])
      setServices(response["data"])
      if (queryParams.get('type') !== null) {
        setType(queryParams.get('type'))
        typeTemp = queryParams.get('type')
        if (queryParams.get('type') !== null) {
          setType(queryParams.get('type'))
          typeTemp = queryParams.get('type')
          if (queryParams.get('type') == "Service") {
            setAdsTypeRentalData(false)
            setAdsTypeCategoryData(false)
            setAllCategory(response["data"])
          }
        }
      }
    });
    const url3 = LocalIP + "machinery/area/";
    axios.get(url3).then((response) => {
      console.log(response["data"])
      setArea(response["data"])
    });
    const url4 = LocalIP + "machinery/sub/";
    axios.get(url4).then((response) => {
      console.log(response["data"])
      setSub_machinery_temp(response["data"])
      const subs_cat = response["data"].filter(one => one.category_id * 1 === queryParams.get('category') * 1);
      if (subs_cat.length !== 0) {
        setSub_machinery(subs_cat)
        setAdsTypeCategoryData(true)
      } else {
        setSub_machinery([])
        setAdsTypeCategoryData(false)
      }
    });
    queryParams.get(queryParams.get('filter'))
    if (queryParams.get('filter') === "true") {
      const url1 = LocalIP + "ads/search_filter_options";
      setSearch(queryParams.get('search'))
      setCategory(queryParams.get('category'))
      setAreaSearch(queryParams.get('area'))
      setType(queryParams.get('type'))
      setSubCategory(queryParams.get('subCategory'))
      setModel(queryParams.get('model'))
      setManufacture(queryParams.get('manufacture'))
      setMinPrice(queryParams.get('minPrice'))
      setMaxPrice(queryParams.get('maxPrice'))
      axios.get(url1, {
        headers: { "Content-Type": "application/json" }, params: {
          start: 0,
          length: pageSize,
          search: queryParams.get('search'),
          category: queryParams.get('category'),
          area: queryParams.get('area'),
          type: queryParams.get('type'),
          subCategory: queryParams.get('subCategory'),
          model: queryParams.get('model'),
          manufacture: queryParams.get('manufacture'),
          minPrice: queryParams.get('minPrice'),
          maxPrice: queryParams.get('maxPrice')
        }
      }).then((response) => {
        console.log(response["data"])
        setAllads(response["data"])
        setLoading(false)
      });
      const url2 = LocalIP + "ads/search_filter_options/total";
      axios.get(url2, {
        headers: { "Content-Type": "application/json" }, params: {
          start: 0,
          length: pageSize,
          search: queryParams.get('search'),
          category: queryParams.get('category'),
          area: queryParams.get('area'),
          type: queryParams.get('type'),
          subCategory: queryParams.get('subCategory'),
          model: queryParams.get('model'),
          manufacture: queryParams.get('manufacture'),
          minPrice: queryParams.get('minPrice'),
          maxPrice: queryParams.get('maxPrice')
        }
      }).then((response) => {
        console.log(response["data"])
        setTotalAds(response["data"][0].total_rows)
        setPagesCount(Math.ceil(response["data"][0].total_rows / pageSize))
        console.log(Math.ceil(response["data"][0].total_rows / pageSize))
        setLoading(false)
      });
    } else {
      if (queryParams.get('search') !== null || queryParams.get('type') !== null || queryParams.get('category') !== null || queryParams.get('area') !== null) {
        var searchTemp = "", categoryTemp = "", areaTemp = "", typeTemp = ""
        if (queryParams.get('search') !== null) {
          setSearch(queryParams.get('search'))
          searchTemp = queryParams.get('search')
        }
        if (queryParams.get('category') !== null) {
          setCategory(queryParams.get('category'))
          categoryTemp = queryParams.get('category')
        }
        if (queryParams.get('area') !== null) {
          setAreaSearch(queryParams.get('area'))
          areaTemp = queryParams.get('area')
        }
        if (queryParams.get('type') !== null) {
          typeTemp = queryParams.get('type')
        }
        const url1 = LocalIP + "ads/search_options";
        axios.get(url1, {
          headers: { "Content-Type": "application/json" }, params: {
            start: 0,
            length: pageSize,
            search: searchTemp,
            type: typeTemp,
            category: categoryTemp,
            area: areaTemp
          }
        }).then((response) => {
          console.log(response["data"])
          setAllads(response["data"])
          setLoading(false)
        });
        const url2 = LocalIP + "ads/search_options/total";
        axios.get(url2, {
          headers: { "Content-Type": "application/json" }, params: {
            start: 0,
            length: pageSize,
            search: searchTemp,
            type: typeTemp,
            category: categoryTemp,
            area: areaTemp
          }
        }).then((response) => {
          console.log(response["data"])
          setTotalAds(response["data"][0].total_rows)
          setPagesCount(Math.ceil(response["data"][0].total_rows / pageSize))
          console.log(Math.ceil(response["data"][0].total_rows / pageSize))
          setLoading(false)
        });
      } else {
        const url1 = LocalIP + "ads/all";
        axios.get(url1, {
          headers: { "Content-Type": "application/json" }, params: {
            start: 0,
            length: pageSize
          }
        }).then((response) => {
          console.log(response["data"])
          setAllads(response["data"])
          setLoading(false)
        });
        const url2 = LocalIP + "ads/total";
        axios.get(url2).then((response) => {
          console.log(response["data"])
          setTotalAds(response["data"][0].total_rows)
          setPagesCount(Math.ceil(response["data"][0].total_rows / pageSize))
          console.log(Math.ceil(response["data"][0].total_rows / pageSize))
          setLoading(false)
        });
      }
    }
  };

  const setCategoryForm = (e) => {
    setCategory(e.target.value)
    if (type == "Rental" || type == "Trading") {
      const subs_cat = sub_machinery_temp.filter(one => one.category_id * 1 === e.target.value * 1);
      if (subs_cat.length !== 0) {
        setSub_machinery(subs_cat)
        setAdsTypeCategoryData(true)
      } else {
        setSub_machinery([])
        setAdsTypeCategoryData(false)
      }
    }
  }

  const pageData = (page, size) => {
    const all_ads = document.getElementById('all_ads');
    all_ads.focus()
    if (search === "" && type === "" && category === "" && subCategory === "" && model === "" && manufacture === "" && areaSearch === "" && minPrice === "" && maxPrice === "") {
      console.log(page, size)
      setLoading(true)
      setPageNum(page)
      if (page == 1) {
        page = 0;
      } else {
        page = (page - 1) * size
      }
      const url1 = LocalIP + "ads/all";
      axios.get(url1, {
        headers: { "Content-Type": "application/json" }, params: {
          start: page,
          length: size
        }
      }).then((response) => {
        console.log(response["data"])
        setAllads(response["data"])
        setLoading(false)
      });
      if (size !== pageSize) {
        setPageNum(1)
        const url2 = LocalIP + "ads/total";
        axios.get(url2).then((response) => {
          console.log(response["data"])
          setTotalAds(response["data"][0].total_rows)
          setPagesCount(Math.ceil(response["data"][0].total_rows / size))
          console.log(Math.ceil(response["data"][0].total_rows / size))
          setLoading(false)
        });
      }
    } else {
      console.log("test123")
      setLoading(true)
      if (page == 1) {
        page = 0;
      } else {
        page = (page - 1) * size
      }
      console.log("kk")
      const queryParams = new URLSearchParams(location.search);
      const url1 = LocalIP + "ads/search_filter_options";
      axios.get(url1, {
        headers: { "Content-Type": "application/json" }, params: {
          start: page,
          length: size,
          search: queryParams.get('search'),
          category: queryParams.get('category'),
          area: queryParams.get('area'),
          type: queryParams.get('type'),
          subCategory: queryParams.get('subCategory'),
          model: queryParams.get('model'),
          manufacture: queryParams.get('manufacture'),
          minPrice: queryParams.get('minPrice'),
          maxPrice: queryParams.get('maxPrice')
        }
      }).then((response) => {
        console.log(response["data"])
        setAllads(response["data"])
        setLoading(false)
      });
      if (size !== pageSize) {
        setPageNum(1)
        const url2 = LocalIP + "ads/search_filter_options/total";
        axios.get(url2, {
          headers: { "Content-Type": "application/json" }, params: {
            start: 0,
            length: size,
            search: queryParams.get('search'),
            category: queryParams.get('category'),
            area: queryParams.get('area'),
            type: queryParams.get('type'),
            subCategory: queryParams.get('subCategory'),
            model: queryParams.get('model'),
            manufacture: queryParams.get('manufacture'),
            minPrice: queryParams.get('minPrice'),
            maxPrice: queryParams.get('maxPrice')
          }
        }).then((response) => {
          console.log(response["data"])
          setTotalAds(response["data"][0].total_rows)
          setPagesCount(Math.ceil(response["data"][0].total_rows / size))
          console.log(Math.ceil(response["data"][0].total_rows / size))
          setLoading(false)
        });
      }
    }
  }

  const pageSelect = (num) => {
    setPageNum(num)
    const all_adsDiv = document.getElementById('all_ads');
    if (all_adsRef.current) {
      all_adsRef.current.click()
      all_adsRef.current.focus()
    }
    all_adsDiv.setAttribute('tabindex', '-1')
    console.log(all_adsRef.current)
  }

  const paginationPrint = (count) => {
    const listItems = [];
    for (let i = 1; i <= count; i++) {
      if (((i <= (pageNum + 2)) && (i >= (pageNum - 2))) || (pageNum === 1 && (i <= (pageNum + 4))) || (pageNum === 2 && (i <= (pageNum + 3))) || (pageNum === pagesCount && (i >= (pageNum - 4))) || (pageNum === pagesCount - 1 && (i >= (pageNum - 3)))) {
        listItems.push(<li className={i === pageNum ? 'page-item selected-page' : 'page-item'}>
          <a class="text-dark page-link" onClick={() => { pageSelect(i); pageData(i, pageSize); }} >{i}</a>
        </li>);
      }
    }
    return listItems;
  };

  const searchSubmit = async (e) => {
    e.preventDefault();
    var searchData = "/all_ads?"
    if (search !== "") {
      searchData = searchData + "search=" + search + "&"
    }
    if (type !== "") {
      searchData = searchData + "type=" + type + "&"
    }
    if (category !== "") {
      searchData = searchData + "category=" + category + "&"
    }
    if (areaSearch !== "") {
      searchData = searchData + "area=" + areaSearch + "&"
    }
    history.push(searchData);
    window.location.reload(true)
  };


  const filterSubmit = async (e) => {
    e.preventDefault();
    var queryParams = new URLSearchParams({
      search: search,
      category: category,
      area: areaSearch,
      filter: true,
      type: type,
      subCategory: subCategory,
      model: model,
      manufacture: manufacture,
      minPrice: minPrice,
      maxPrice: maxPrice
    }).toString();
    history.push(`/all_ads?${queryParams}`);
    window.location.reload(true)
  }

  const date_time_only = (date_time) => {
    const date = new Date(date_time);
    const datePart = date.toISOString().split('T')[0];
    const timePart = date.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return `${datePart} ${timePart}`;
  };

  return (
    <Container>
      <br />
      <div id="all_ads" ref={all_adsRef} tabIndex={0} className="web-view">
        <div className="search-bar-container">
          <Form className="d-flex" onSubmit={searchSubmit}><Form.Select aria-label="Type select" className="me-2 home-select-items" value={type} onChange={e => { setTypeForm(e) }}>
            <option value="">Looking for?</option>
            <option value="Rental">Rental</option>
            <option value="Service">Service</option>
            <option value="Trading">Trading</option>
          </Form.Select>
            <Form.Select aria-label="Category select" className="me-2 home-select-items" value={category} onChange={setCategoryForm}>
              <option value="">Category</option>
              {
                allCategory.map((res, index) =>
                  <option value={res.id}>{res.name}</option>
                )
              }
            </Form.Select>
            <Form.Select aria-label="Area select" className="me-2 home-select-items" value={areaSearch} onChange={e => { setAreaSearch(e.target.value) }}>
              <option value="">Area</option>
              {
                area.map((res, index) =>
                  <option value={res.id}>{res.a_name}</option>
                )
              }
            </Form.Select>
            <InputGroup>
              <FormControl
                type="text"
                placeholder="Search"
                aria-label="Search"
                value={search} onChange={e => { setSearch(e.target.value) }}
              />
              <button type='submit' variant="warning" className='btn btn-warning' >
                <FaSearch />
              </button>
            </InputGroup>
          </Form>
        </div>

        <hr />
        <div className="search-bar-container">
          <Row>
            <Col md={3} >
              <Form onSubmit={filterSubmit}>
                <Form.Group controlId="type" className="mb-3">
                  <Form.Label className="left-label">Advertisement Type</Form.Label>
                  <Form.Control as="select" value={type} onChange={setTypeForm} className='form-control-select-ads'>
                    <option value="">Select One</option>
                    <option value="Rental">Rental</option>
                    <option value="Service">Service</option>
                    <option value="Trading">Trading</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="category" className="mb-3">
                  <Form.Label className="left-label">Category</Form.Label>
                  <Form.Control as="select" value={category} onChange={setCategoryForm} className='form-control-select-ads'>
                    <option value="">Select One</option>
                    {
                      allCategory.map((res, index) =>
                        <option value={res.id}>{res.name}</option>
                      )
                    }
                  </Form.Control>
                </Form.Group>
                {
                  adsTypeCategoryData && (
                    <Form.Group controlId="subCategory" className="mb-3">
                      <Form.Label className="left-label">Sub Category</Form.Label>
                      <Form.Control as="select" value={subCategory} onChange={e => { setSubCategory(e.target.value) }} className='form-control-select-ads'>
                        <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                        {
                          sub_machinery.map((res, index) =>
                            <option value={res.id}>{res.sub_category_name}</option>
                          )
                        }
                      </Form.Control>
                    </Form.Group>
                  )
                }
                {
                  adsTypeRentalData && (
                    <Form.Group controlId="model" className="mb-3">
                      <Form.Label className="left-label">Model</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter model"
                        value={model}
                        onChange={e => { setModel(e.target.value) }}
                      />
                    </Form.Group>
                  )}
                {
                  adsTypeRentalData && (
                    <Form.Group controlId="yom" className="mb-3">
                      <Form.Label className="left-label">Year of Manufacture</Form.Label>
                      <Form.Control
                        as="select"
                        className='form-control-select-ads'
                        value={manufacture}
                        onChange={e => { setManufacture(e.target.value) }}
                      >
                        <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                        {Array.from({ length: (new Date().getFullYear() * 1) - 1960 + 1 }, (_, index) => (
                          <option key={index} value={(new Date().getFullYear() * 1) - index}>{(new Date().getFullYear() * 1) - index}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                {
                  adsTypeRentalData && (
                    <Form.Group controlId="price" className="mb-3">
                      <Form.Label className="left-label">Price Range</Form.Label>
                      <Row>
                        <Col md={5}>
                          <Form.Control
                            type="number"
                            placeholder="Min Price"
                            value={minPrice}
                            onChange={e => { setMinPrice(e.target.value) }}
                          />
                        </Col>
                        <Col md={5}>
                          <Form.Control
                            type="number"
                            placeholder="Max Price"
                            value={maxPrice}
                            onChange={e => { setMaxPrice(e.target.value) }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  )}
                <br />
                <button type="submit" className='btn btn-warning'>Apply Filters</button>
              </Form>
              <br />
              <br />
            </Col>
            <Col md={9} className="vertical-line">
              {loading ? (<Spinner animation="border" />) : (<div>
                <Row><p className='text-left' style={{ marginLeft: '10px' }}>Showing {pageNum == 1 ? (1) : ((pageNum - 1) * pageSize)}-{pageSize > totalAds ? (totalAds) : (pageSize * pageNum < totalAds ? (pageSize * pageNum) : (totalAds))} of {totalAds}</p></Row>
                <Row>
                  {
                    allads.map((res, index) =>
                      <Col className="ads-card-view">
                        <a href={(localStorage.getItem("privilege") * 1 === 1) ? ("/admin_ads_view/" + res.id) : ("/ads_view/" + res.id)} >
                          <Card className="ads-card-view">
                            <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                            <Card.Body>
                              <Card.Text>
                                <p className="card-title" title={res.AD_TITLE}>{ads_title_limit(res.AD_TITLE)}</p>
                                <p>
                                  {res.MODEL && <>Model: {res.MODEL}<br /></>}
                                  {(res.YOM || res.YOM !== 0) && <>Yom: {res.YOM}<br /></>}
                                  {(res.price || res.price !== 0) && <>{res.price} AED</>}
                                </p>
                              </Card.Text>
                              <Row>
                                <Col lg={6} xs={6}>
                                  <button className='btn btn-warning view-details' alt="View Details"><BiSolidDetail /> Details</button>
                                </Col>
                                <Col lg={4} xs={4} style={{ marginTop: '5px', fontSize: '12px' }}>
                                  <span title={date_time_only(res.date_time)}>{timeAgo(res.date_time)}</span>
                                </Col>
                                <Col lg={2} xs={2}>
                                  {
                                    res.AD_TYPE.toLowerCase() == "rental" ? (<div className="dot green-dot" title="Rental" />) :
                                      (
                                        res.AD_TYPE.toLowerCase() == "service" ?
                                          (<div className="dot red-dot" title="Service" />) : (<div className="dot blue-dot" title="Trading" />))
                                  }
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </a>
                      </Col>
                    )
                  }
                  {(allads.length % 3 != 0) && (<Col></Col>)}
                </Row>
                <br />
                {totalAds == 0 ? (<Row></Row>) : (
                  <Row>
                    <Col>
                      <ul class="pagination pagination-l">
                        <li className={pageNum === 1 ? 'page-item disabled' : 'page-item'} >
                          <a class="text-dark page-link" onClick={() => { pageSelect(pageNum - 1); pageData(pageNum - 1, pageSize); }}>Previous</a>
                        </li>
                        {paginationPrint(pagesCount)}
                        <li className={pageNum === pagesCount ? 'page-item disabled' : 'page-item'} >
                          <a class="text-dark page-link" onClick={() => { pageSelect(pageNum + 1); pageData(pageNum + 1, pageSize); }}>Next</a>
                        </li>
                      </ul>
                    </Col>
                    <Col>
                      <div className='d-flex flex-column align-items-end'>
                        <Form.Control as="select" className='form-control-ads-page-size' value={pageSize} onChange={e => { setPageSize(e.target.value); pageData(1, e.target.value) }}>
                          <option value="12">12</option>
                          <option value="24">24</option>
                          <option value="60">60</option>
                        </Form.Control>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div id="all_ads" className="mobile-view">
        <Drawer
          title="Filter options"
          placement="left"
          onClose={onClose}
          visible={visible}
          width="100%"
        >
          <Form onSubmit={filterSubmit}>
            <Form.Group controlId="type" className="mb-3">
              <Form.Label className="left-label">Advertisement Type</Form.Label>
              <Form.Control as="select" value={type} onChange={setTypeForm} className='form-control-select-ads'>
                <option value="">Select One</option>
                <option value="Rental">Rental</option>
                <option value="Service">Service</option>
                <option value="Trading">Trading</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="category" className="mb-3">
              <Form.Label className="left-label">Category</Form.Label>
              <Form.Control as="select" value={category} onChange={setCategoryForm} className='form-control-select-ads'>
                <option value="">Select One</option>
                {
                  allCategory.map((res, index) =>
                    <option value={res.id}>{res.name}</option>
                  )
                }
              </Form.Control>
            </Form.Group>
            {
              adsTypeCategoryData && (
                <Form.Group controlId="subCategory" className="mb-3">
                  <Form.Label className="left-label">Sub Category</Form.Label>
                  <Form.Control as="select" value={subCategory} onChange={e => { setSubCategory(e.target.value) }} className='form-control-select-ads'>
                    <option value="" style={{ color: "#a3a3a3" }}>Select One</option>
                    {
                      sub_machinery.map((res, index) =>
                        <option value={res.id}>{res.sub_category_name}</option>
                      )
                    }
                  </Form.Control>
                </Form.Group>
              )
            }
            {
              adsTypeRentalData && (
                <Form.Group controlId="model" className="mb-3">
                  <Form.Label className="left-label">Model</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter model"
                    value={model}
                    onChange={e => { setModel(e.target.value) }}
                  />
                </Form.Group>
              )}
            {
              adsTypeRentalData && (
                <Form.Group controlId="yom" className="mb-3">
                  <Form.Label className="left-label">Year of Manufacture</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter year of manufacture"
                    value={manufacture}
                    onChange={e => { setManufacture(e.target.value) }}
                  />
                </Form.Group>
              )}
            {
              adsTypeRentalData && (
                <Form.Group controlId="price" className="mb-3">
                  <Form.Label className="left-label">Price Range</Form.Label>
                  <Row>
                    <Col md={5} xs={5}>
                      <Form.Control
                        type="number"
                        placeholder="Min Price"
                      />
                    </Col>
                    <Col md={5} xs={5}>
                      <Form.Control
                        type="number"
                        placeholder="Max Price"
                      />
                    </Col>
                  </Row>
                </Form.Group>
              )}
            <br />
            <button type="submit" className='btn btn-warning'>Apply Filters</button>
          </Form>
        </Drawer>
        <div className="search-bar-m-container">
          <Form onSubmit={searchSubmit}>
            <Row className="mb-3">
              <Col xs={12} md={12} lg={12}>
                <select aria-label="Category select" className='form-control form-control-select' value={type} onChange={e => { setTypeForm(e) }}>
                  <option value="">Looking for?</option>
                  <option value="Rental">Rental</option>
                  <option value="Service">Service</option>
                  <option value="Trading">Trading</option>
                </select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} lg={12}>
                <select aria-label="Category select" className='form-control form-control-select' value={category} onChange={e => { setCategory(e.target.value) }}>
                  <option>Category</option>
                  {
                    allCategory.map((res, index) =>
                      <option value={res.id}>{res.name}</option>
                    )
                  }
                </select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={12} lg={12}>
                <select aria-label="Area select" className='form-control form-control-select' value={areaSearch} onChange={e => { setAreaSearch(e.target.value) }}>
                  <option>Area</option>
                  {
                    area.map((res, index) =>
                      <option value={res.id}>{res.a_name}</option>
                    )
                  }
                </select>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <InputGroup>
                  <FormControl
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    value={search}
                    onChange={e => { setSearch(e.target.value) }}
                  />
                  <Button variant="warning" type='submit' >
                    <FaSearch />
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </div>
        <Row className='text-left'>
          <p><button type="submit" onClick={showDrawer} className='btn btn-warning'><FaFilter /></button> Filter options</p>
        </Row>
        <Row>
          <p className='text-left' style={{ marginLeft: '10px' }}>Showing {pageNum == 1 ? (1) : ((pageNum - 1) * pageSize)}-{pageSize > totalAds ? (totalAds) : (pageSize * pageNum < totalAds ? (pageSize * pageNum) : (totalAds))} of {totalAds}</p>
        </Row>
        <Row>
          {
            allads.map((res, index) =>
              <Col className="ads-card-view">
                <a href={(localStorage.getItem("privilege") * 1 === 1) ? ("/admin_ads_view/" + res.id) : ("/ads_view/" + res.id)}>
                  <Card className="ads-card-view">
                    <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                    <Card.Body>
                      <Card.Text>
                        <p className="card-title" title={res.AD_TITLE}>{ads_title_limit(res.AD_TITLE)}</p>
                        <p>
                          {res.MODEL && <>Model: {res.MODEL}<br /></>}
                          {(res.YOM || res.YOM !== 0) && <>Yom: {res.YOM}<br /></>}
                          {(res.price || res.price !== 0) && <>{res.price} AED</>}
                        </p>
                      </Card.Text>
                      <Row>
                        <Col lg={6} xs={6}>
                          <button className='btn btn-warning view-details' alt="View Details"><BiSolidDetail /> More</button>
                        </Col>
                        <Col lg={4} xs={4} style={{ marginTop: '5px', fontSize: '12px' }}>
                          <span>{timeAgo(res.date_time)}</span>
                        </Col>
                        <Col lg={2} xs={2}>
                          {
                            res.AD_TYPE.toLowerCase() == "rental" ? (<div className="dot green-dot" title="Rental" />) :
                              (
                                res.AD_TYPE.toLowerCase() == "service" ?
                                  (<div className="dot red-dot" title="Service" />) : (<div className="dot blue-dot" title="Trading" />))
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            )
          }
        </Row>
        <br />
        {totalAds == 0 ? (<Row></Row>) : (
          <Row>
            <Col>
              <ul class="pagination pagination-l">
                <li className={pageNum === 1 ? 'page-item disabled' : 'page-item'} >
                  <a class="text-dark page-link" onClick={() => { pageSelect(pageNum - 1); pageData(pageNum - 1, pageSize); }}>Previous</a>
                </li>
                {paginationPrint(pagesCount)}
                <li className={pageNum === pagesCount ? 'page-item disabled' : 'page-item'} >
                  <a class="text-dark page-link" onClick={() => { pageSelect(pageNum + 1); pageData(pageNum + 1, pageSize); }}>Next</a>
                </li>
              </ul>
            </Col>
            <Col>
              <div className='d-flex flex-column align-items-end'>
                <Form.Control as="select" className='form-control-ads-page-size' value={pageSize} onChange={e => { setPageSize(e.target.value); pageData(1, e.target.value) }}>
                  <option value="12">12</option>
                  <option value="24">24</option>
                  <option value="60">60</option>
                </Form.Control>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Container >
  );
}

export default All_ads;
